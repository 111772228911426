// src/api.ts

import axios from 'axios';

/**
 * Uploads an image to the server.
 * @param file The image file to upload.
 * @returns A promise that resolves to the raw response from the server as a string.
 */
export const uploadImage = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      maxContentLength: 10 * 1024 * 1024, // Adjust the max content length as needed
    });
    if (response.status === 201) {
      return response.data; // Return the raw response data as a string
    } else {
      console.error('Failed to upload image', response);
      throw new Error('Failed to upload image');
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

/**
 * Retrieves an image by its ID.
 * @param imageId The ID of the image to retrieve.
 * @returns A promise that resolves to a Blob representing the image data.
 */
export const getImageById = async (imageId: string): Promise<Blob> => {
  try {
    const response = await axios.get(`/a/${imageId}`, {
      responseType: 'blob',
    });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Failed to retrieve image', response);
      throw new Error('Failed to retrieve image');
    }
  } catch (error) {
    console.error('Error retrieving image:', error);
    throw error;
  }
};