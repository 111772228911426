// src/image/ImagePage.tsx

import React from 'react';
import { useParams } from 'react-router-dom';
import './ImagePage.css'; // Import the CSS file

const ImagePage: React.FC = () => {
  const { imageId } = useParams<{ imageId: string }>(); // Get the imageId from the URL
  const imageUrl = `${window.location.origin}/a/${imageId}`; // Construct the direct link to the image including the current host


  const handleCopyLink = () => {
    navigator.clipboard.writeText(imageUrl).then(() => {
      alert('Link copied to clipboard!');
    }).catch((error) => {
      console.error('Failed to copy the link:', error);
    });
  };

  return (
    <div className="image-page">
     <a href="/"> <img src="/logo512.png" alt="Website Logo" className="logo" /> {/* Website logo */}</a>
      <h1>Your Image</h1>
      {imageId ? (
        <div>
          <div className="image-container">
            <img
              src={imageUrl}
              alt={`Image ${imageId}`}
              style={{ width: '300px', height: '300px', objectFit: 'cover' }} // Fixed size box
            />
          </div>
          <p>Direct Link: <a href={imageUrl} target="_blank" rel="noopener noreferrer">{imageUrl}</a></p>
          <button onClick={handleCopyLink}>Copy Link</button>
        </div>
      ) : (
        <p>Image not found</p>
      )}
    </div>
  );
};

export default ImagePage;
